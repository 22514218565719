// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fade from 'react-reveal/Fade';
import map from 'lodash/map';

import BodySection from '~plugins/material-ui/components/BodySection';
import ContactForm from '~components/ContactForm';
import useContactForm from '~components/ContactForm/useContactForm';
import useContactFormValidationSchema from '~components/ContactForm/useContactFormValidationSchema';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import RichText from '~plugins/prismic/components/RichText';
import Header from '~components/Header';
import getContactFormTrackingProps from '~helpers/getContactFormTrackingProps';
import useSiteInfo from '../hooks/useSiteInfo';
import useSetAppBarState from '~components/AppBar/hooks/useSetAppBarState';
import type { PrismicContactPage } from '~schema';

export type ClassKey =
  | 'root'
  | 'itemGridContainer'
  | 'itemGridItemForm'
  | 'itemGridItemInfo'
  | 'contactWrapper'
  | 'contactTitle'
  | 'contactInfo'
  | 'contactInfoTitle'
  | 'extraContactInfo'
  | 'mapWrapper'
  | 'map';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicContactPage>>,
};

const CONTACT_PAGE_INPUT_SECTOR = 'Otros';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  itemGridContainer: {},
  itemGridItemForm: {},
  itemGridItemInfo: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  contactWrapper: {},
  contactTitle: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(4),
  },
  contactInfo: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(4),
    },
  },
  contactInfoTitle: {
    color: theme.palette.common.black,
    '& > *': {
      paddingBottom: theme.spacing(1),
    },
  },
  extraContactInfo: {
    paddingTop: theme.spacing(2),
  },
  mapWrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
  map: { height: 400 },
});

const ContactPage = ({ classes, node }: Props) => {
  const contactForm = useContactForm();
  useSetAppBarState({
    overlayColor: node?.data?.overlay_color,
    backgroundImage: node?.data?.background_image,
  });

  const siteInfo = useSiteInfo();

  return (
    <div className={classes.root}>
      <Header
        componentVariant="simple"
        title={node?.data?.title}
        subtitle={node?.data?.subtitle}
        backgroundImage={node?.data?.background_image}
        overlayColor={node?.data?.overlay_color}
      />
      <BodySection className={classes.contactWrapper}>
        <Grid container spacing={8} className={classes.itemGridContainer}>
          <Grid item xs={12} md={6} className={classes.itemGridItemForm}>
            <RichText
              {...node?.data?.contact_title}
              className={classes.contactTitle}
            />
            <ContactForm
              {...getContactFormTrackingProps({
                inputSector: CONTACT_PAGE_INPUT_SECTOR,
              })}
              node={contactForm}
              listKey={undefined}
              validationSchema={useContactFormValidationSchema(contactForm)}
              buttonColor="primary"
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.itemGridItemInfo}>
            <Fade bottom>
              <div className={classes.contactInfo}>
                <RichText
                  {...node?.data?.contact_phone}
                  className={classes.contactInfoTitle}
                />
                <RichText {...siteInfo?.data?.contact_phone} />
              </div>
              <div className={classes.contactInfo}>
                <RichText
                  {...node?.data?.contact_email}
                  className={classes.contactInfoTitle}
                />
                <RichText {...siteInfo?.data?.contact_email} />
              </div>
              <div className={classes.contactInfo}>
                <RichText
                  {...node?.data?.contact_address}
                  className={classes.contactInfoTitle}
                />
                <RichText {...siteInfo?.data?.contact_address} />
                {siteInfo?.data?.other_addresses
                  ? map(
                      siteInfo?.data?.other_addresses,
                      (address, index: number) => (
                        <div key={index} className={classes.extraContactInfo}>
                          <RichText {...address?.address_title} />
                          <RichText {...address?.address} />
                        </div>
                      ),
                    )
                  : null}
              </div>
            </Fade>
          </Grid>
        </Grid>
      </BodySection>
      {node?.data?.contact_map_link?.url ? (
        <BodySection className={classes.mapWrapper}>
          <div className={classes.map}>
            <iframe
              title="invelon-map"
              src={node?.data?.contact_map_link?.url}
              frameBorder="0"
              width="100%"
              height="100%"
              aria-hidden="false"
            />
          </div>
        </BodySection>
      ) : null}
    </div>
  );
};

export default compose(
  withNodePage<PrismicContactPage, *>({
    getNode: data => data?.prismicContactPage,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'ContactPage' }),
)(ContactPage);

export const query = graphql`
  query PrismicContactPage($prismicId: ID) {
    prismicContactPage(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        background_image {
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        overlay_color
        contact_title {
          text
          html
        }
        contact_address {
          text
          html
        }
        contact_email {
          text
          html
        }
        contact_phone {
          text
          html
        }
        contact_map_link {
          link_type
          url
        }
      }
    }
  }
`;
